<template>
  <div>
    <CCard>
      <CCardBody>
        <TTabs :tabs="tabs" @change-tab="changeTab" :activeTab="activeTab">
          <template v-for="tab in tabs" #[tab.key]>
            <div :key="tab.key">
              <slot
                name="table"
                :items="getDataByKey(tab.key)"
                :shipment_unit="tab.key"
              />
            </div>
          </template>
        </TTabs>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    prices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters({
      freight_charge_types: "warehouse.freight_charge_types.list",
    }),
    tabs() {
      return this.freight_charge_types.map((item) => ({
        key: item.id,
        name: item.name,
        icon: "cisTruck",
      }));
    },
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    getDataByKey(type) {
      return this.prices.filter((i) => {
        let unit_condition_value = i.conditions.find(
          (i) => i.key === "unit"
        )?.value;

        return unit_condition_value === type;
      });
    },
  },
};
</script>
