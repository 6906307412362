<template>
  <div>
    <TTabs :tabs="tabs" @change-tab="changeTab" :activeTab="$route.query.tab">
      <template v-for="tab in tabs" #[tab.key]>
        <ShipmentTabContent
          :prices="getDataByKey(tab.key)"
          v-if="tab.key === 'shipping-fee'"
          :key="tab.key"
        >
          <template #table="{ items, shipment_unit }">
            <TablePrice
              :data_key="tab.key"
              :prices="items"
              @update:showModalCreatePrice="
                onShowModalCreatePrice(tab.key, [
                  { key: 'unit', value: shipment_unit },
                ])
              "
            />
          </template>
        </ShipmentTabContent>

        <TablePrice
          :key="tab.key"
          :data_key="tab.key"
          :prices="getDataByKey(tab.key)"
          @update:showModalCreatePrice="onShowModalCreatePrice(tab.key)"
          v-else
        />
      </template>
    </TTabs>

    <ModalCreatePrice ref="modalCreatePrice" @success="onCreatePriceSuccess" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCreatePrice from "../components/ModalCreatePrice.vue";
import TablePrice from "../components/TablePrice.vue";
import organizationStore from "@/core/services/tomoni/organization.local";
import ShipmentTabContent from "../components/ShipmentTabContent.vue";

export default {
  components: {
    ModalCreatePrice,
    TablePrice,
    ShipmentTabContent,
  },
  data() {
    return {
      tabs: [
        {
          key: "shipping-fee",
          name: "Shipping fee",
          icon: "cis-library-books",
        },
        {
          key: "exchange-rates",
          name: "Exchange rate",
          icon: "cis-map",
        },
        {
          key: "insurance-fee",
          name: "Insurance fee",
          icon: "cis-border-all",
        },
        {
          key: "special-goods-fee",
          name: "Special goods fee",
          icon: "cis-list-rich",
        },
      ],
    };
  },
  created() {
    // Lấy dữ liệu dùng cho các select bên trong table (SModalPriceCondition)
    this.$store.dispatch("helper.prices.apply-query");
    this.$store.dispatch("accounting.currencies.fetch.if-first-time");
    this.$store.dispatch("warehouse.shipment_methods.fetch.if-first-time");
    this.$store.dispatch("warehouse.areas.fetch.if-first-time");
    this.$store.dispatch("warehouse.freight_charge_types.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      prices: "helper.prices.list",
    }),
    organization_id() {
      return organizationStore.get();
    },
    prices_refer_organization() {
      return this.prices.filter((price) => {
        let condition_organization = (price?.conditions || []).find(
          (c) => c.key === "organization"
        )?.value;
        let condition_type = (price?.conditions || []).find(
          (c) => c.key === "type"
        )?.value;

        if (
          !this.organization_id ||
          !condition_organization ||
          ["exchange-rates"].includes(condition_type)
        )
          return true;

        return condition_organization === this.organization_id;
      });
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
    onShowModalCreatePrice(type, default_conditions) {
      this.$refs.modalCreatePrice.showModal(type, default_conditions);
    },
    getDataByKey(type) {
      return this.prices_refer_organization.filter((price) => {
        let condition_type = (price?.conditions || []).find(
          (c) => c.key === "type"
        )?.value;

        return condition_type === type;
      });
    },
    onCreatePriceSuccess() {
      this.$store.dispatch("helper.prices.fetch");
    },
  },
};
</script>
